import { WidgetProvider } from "context/widget.context";
import WidgetMapper from "./widget-mapper";

export default function App({ type, slug, ...props }) {
  return (
    <WidgetProvider formSlug={slug} widgetProps={{ ...props, widgetType: type }}>
      <WidgetMapper widgetType={type} />
    </WidgetProvider>
  );
}
