export const flattenFormErrors = (fe = {}) =>
  Object.keys(fe)
    .map((fieldName) => ({
      [fieldName]: Array.isArray(fe[fieldName]) ? fe[fieldName].join(' ') : fe[fieldName],
    }))
    .reduce((prev, curr) => ({ ...prev, ...curr }), {});

export const flattenErrors = ({ general_errors: ge = [], form_errors: fe = {} }) => {
  const formErrors = flattenFormErrors(fe);
  return [
    ...ge,
    ...Object.keys(formErrors).map((fieldName) => `${fieldName}: ${formErrors[fieldName]}`),
  ]
};

export const setErrors = (err, formSetError) => {
  if (!formSetError) {
    flattenErrors(err);
    return;
  }
  if (err.form_errors && formSetError) {
    const fieldsHasError = Object.keys(err.form_errors)
    fieldsHasError.map(field => {
      let fieldErrors = ''
      err.form_errors[field].map(err => {
        fieldErrors = `${fieldErrors} - ${err} `
      })
      formSetError(field, { message: fieldErrors })
    })
  }
};
