import APISettings from '../../settings/api.settings'


export const config = ({ apiKey = APISettings.apiKey, ...settings }) => ({
  validateStatus: status => status < 500,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': apiKey,
    accept: 'application/json',
  },
  ...settings
})


export const createFormData = formObject => {
  let formData = new FormData()

  for (const field in formObject) {
    const fieldValue = formObject[field]
    if (!["", null, undefined].includes(fieldValue)) {
      if (typeof fieldValue === 'object') {

        if (fieldValue instanceof FileList) {
          // it's a file
          if (fieldValue.length === 1) {
            formData.append(field, fieldValue[0])
          } else {
            for (let i = 0; i < fieldValue.length; i++) {
              formData.append(`${field}[]`, fieldValue[i])
            }
          }
        } else if (Array.isArray(fieldValue)) {
          //it's an array, don't convert to json (it confuses the server)
          formData.append(field, fieldValue)
        } else {
          // it's an object
          formData.append(field, JSON.stringify(fieldValue))
        }

      } else {
        // it's not an object
        formData.append(field, fieldValue)
      }
    }
  }
  return formData
};