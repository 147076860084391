import axios from 'axios'


export default () => {
  axios.interceptors.request.use(config => {
    const params = config.params || {}
    return { ...config, params }
    // return config
  })

  axios.interceptors.response.use(
    response => {
      // Return a successful response back to the calling service
      return response.data
    },
    error => {
      return new Promise((resolve, reject) => {
        reject(error.response.data.errors)
      })
    },
  )
}
