import { config, createFormData } from "./utils";
import API from "settings/api.settings";
import { httpClient } from "./utils/http";

export const form = () => ({
  getOne: (formSlug, params = {}) =>
    httpClient.get(
      `${API.baseUrl}/v3/form-displays/slug/${formSlug}/`,
      config({ params }),
    ),

  submit: (formData, formSlug,  params = {}) =>
    httpClient.post(
      `${API.baseUrl}/v3/form-displays/slug/${formSlug}/submit/`,
      createFormData(formData),
      config({ params }),
    ),
})
