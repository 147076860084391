import { useState, useContext, useEffect } from 'preact/hooks';
import { createContext } from 'preact'
import { form } from 'api';
import { flattenErrors } from 'utils/error';
import { IntlProvider } from 'preact-i18n';
import locales from 'i18n/locales';
import LocaleSettings from 'settings/locale.settings';
import { addWidgetTheme, addThemeCssTag } from 'utils/theme-setup';
import setInitialValues from '../utils/form-static-value-generator';

const WidgetContext = createContext()

const WidgetProvider = ({ formSlug, widgetProps, ...props }) => {

  const [formData, setFormData] = useState(null)
  const [oncePerUser, setUserRestriction] = useState(false)
  const [isWidgetOpen, openWidget] = useState(false)
  const [isFormSubmitted, setFormIsSubmitted] = useState(false)
  const isDrawerSubmittedOnce = localStorage.getItem(`formaloo-widget-submit-${formSlug}`)

  // remove these fields from widget
  const unSupportedFields = ['matrix', 'date', 'time', 'phone_verification', 'meta', 'product', 'barcode', 'location']

  /* 
    the hidden & variable fields should not be displayed on the form,
    and hidden field value is set with alias by the owner 
  */
  const blackListFields = [...unSupportedFields, 'hidden', 'variable'] 

  const onSuccessFetchForm = {
    'drawer': () => openWidget(true),
    'side-widget': () => null,
    'single-step': () => null,
  }

  const setupWidgetTheme = (buttonColor, buttonTextColor) => {
    const styleSheet = addThemeCssTag()
    addWidgetTheme(styleSheet, buttonColor, buttonTextColor)
  }

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const { data, errors } = await form().getOne(formSlug);
        if (data.form) {
          setFormData(formGeneralDataSelector(data.form))
          onSuccessFetchForm[widgetProps.widgetType]()
          setupWidgetTheme(data.form.button_color, data.form.submit_text_color)
          return
        }
        flattenErrors(errors).map(error => console.error(error))
      } catch (errors) {
        flattenErrors(errors).map(error => console.error(error))
      }
    }

    const checkUserRestriction = () => {
  
      const { once_per_user } = widgetProps
      if (typeof once_per_user === "string" && once_per_user == 'true') return true
      if (typeof once_per_user === "boolean" && once_per_user) return true
      return false
    }


    setUserRestriction(checkUserRestriction())
    // show widget to user that never submit this form
    if (checkUserRestriction()) {
      if (isDrawerSubmittedOnce) {
        return null
      }
      fetchForm()
      return
    }
    if (formSlug) {
      fetchForm()
    }
  }, [formSlug, widgetProps])

  const formGeneralDataSelector = (data) => {
    if (!data) return {}
    return {
      logo:                         data.logo,
      title:                        data.title,
      showTitle:                    data.show_title,
      formSlug:                     data.slug,
      mainTheme:                    data.background_color,
      backgroundImage:              data.background_image,
      borderColor:                  data.border_color,
      submitTextColor:              data.submit_text_color,
      description:                  data.description,
      formAddress:                  data.address,
      contentTheme:                 data.field_color,
      textTheme:                    data.text_color,
      hasWatermark:                 data.has_watermark,
      buttonBackground:             data.button_color,
      successMessage:               data.success_message,
      submitButtonText:             data.button_text,
      formType:                     data.form_type,
      timeLimit:                    data.time_limit,
      config:                       data.config,
      showScore:                    data.show_calculations_score_result,
      formCurrency:                 data.form_currency_type,
      themeConfigs:                 data.theme_config,
      formFields:                   data.fields_list.filter(field => !blackListFields.includes(field.type)),
      hiddenFields:                 data.fields_list.filter(field => field.type === 'hidden'),
      // generate initial values by alias fields with url params or the fields passed to script as props
      initialValues:                setInitialValues(data.fields_list, widgetProps.alias_fields)
    }
  }

  const closeTheWidget = () => {
    openWidget(false)
  }
  const closeWidget = (justClose) => {
    if (justClose) {
      closeTheWidget();
      return
    }
    setFormIsSubmitted(true)
    setTimeout(() => {
      openWidget(false)
      // reset submit status
      setFormIsSubmitted(false)
      if (widgetProps.once_per_user) {
        localStorage.setItem(`formaloo-widget-submit-${formSlug}`, true)
      }
    }, 2500);
  }

  return (
    <IntlProvider definition={locales[LocaleSettings.locale]}>
      <WidgetContext.Provider
        value={{
          formData,
          closeWidget,
          openWidget,
          isWidgetOpen,
          isFormSubmitted,
          oncePerUser,
          ...widgetProps
        }}
        {...props}
      />
    </IntlProvider>
  )
}

const useWidget = () => {
  const context = useContext(WidgetContext)
  if (context === undefined) {
    throw new Error(
      `useWidget must be used within a WidgetProvider`,
    )
  }
  return context
}
export { WidgetContext, WidgetProvider, useWidget }
