import { useWidget } from "context/widget.context";
import LazyLoad from 'utils/lazy-load';

// lazy import to decrease production size
const Drawer = LazyLoad({ loader: () => import('./drawer') });
const SideWidget = LazyLoad({ loader: () => import('./side-widget') });
const SingleStep = LazyLoad({ loader: () => import('./single-step') });

export default function WidgetMapper({ widgetType }) {
  const { formData } = useWidget()
  if (!formData) return null
  
  // there are 3 types for widget form:
  // 1. drawer :
  // The drawer pops up after the host website loads.
  // The drawer can be displayed just once for each user or on every page load
  // 2. side-widget
  // it has button on side of browser's window, it is a multi-step form
  //  3. single-step
  // it has button on side of browser's window, it is a single-step form

  const mapWidgetToComponent = () => {
    switch (widgetType) {
      case 'drawer':
        return Drawer
      case 'side-widget':
        return SideWidget
      case 'single-step':
        return SingleStep
      default:
        return null
    }
  }
  const Widget = mapWidgetToComponent()
  return <Widget />
}
