import { jsonColorConvertor } from "./colorConvertor"

const cssTagId = 'widget-theme-tag'

export const addThemeCssTag = () => {
  const cssTag = document.getElementById(cssTagId)

  if (!cssTag) {
    return addCssTag()
  } else {
    return cssTag.sheet
  }
}

const addCssTag = () => {
  const styleEl = document.createElement('style')
  styleEl.setAttribute('id', cssTagId)
  styleEl.setAttribute('type', 'text/css')
  document.head.appendChild(styleEl)
  return styleEl.sheet
}

const applyStylesToStyleSheet = (styleSheet, cssRules) => {
  for (let index = 0; index < cssRules.length; index++) {
    styleSheet.insertRule(cssRules[index], styleSheet.cssRules.length)
  }
}

export const addWidgetTheme = (styleSheet, buttonJsonColor, buttonJsonTextColor) => {
  if (!styleSheet) return
  const buttonColor = jsonColorConvertor(buttonJsonColor)
  const textColor = jsonColorConvertor(buttonJsonTextColor)
  const rules = [
    // common 
    `#formaloo-widget-form .widget-field-theme .widget-likeDislike-theme > input:checked ~ label > svg {
      fill: ${buttonColor};
    }`,
    `#formaloo-widget-form .widget-field-theme .widget-likeDislike-theme > input:checked ~ label {
      border-color: ${buttonColor};
    }`,
    `#formaloo-widget-form .widget-field-theme .widget-likeDislike-theme > label > svg:hover {
      fill: ${buttonColor};
    }`,

    // single step theme
    `#formaloo-widget-form .widget-field-theme .widget-singleStep-checkbox-theme > input:checked ~ label {
      background: ${jsonColorConvertor(buttonJsonColor, 0.7)};
      color: ${textColor};
    }`,
    `#formaloo-widget-form .widget-field-theme .widget-singleStep-choice-theme > input:checked ~ label {
      background: ${jsonColorConvertor(buttonJsonColor, 0.7)};
      color: ${textColor};
    }`,
    `#formaloo-widget-form .widget-field-theme .widget-singleStep-file-preview > figcaption::after {
      background: ${buttonColor};
    }`,
    `#formaloo-widget-form .widget-field-theme .widget-singleStep-file-preview > figcaption .widget-singleStep-file-preview-delete {
      background: ${buttonColor};
      color: ${textColor};
    }`,
    `#formaloo-widget-form .widget-field-theme .widget-singleStep-nps-theme > label:hover  {
      background-color: ${jsonColorConvertor(buttonJsonColor, 0.7)};
      color: ${textColor};
    }`,
    `#formaloo-widget-form .widget-field-theme .widget-singleStep-nps-theme > input:checked ~ label  {
      background-color: ${jsonColorConvertor(buttonJsonColor, 0.7)};
      color: ${textColor};
    }`,
    `#formaloo-widget-form .widget-field-theme .widget-singleStep-textInput-theme {
      outline-color: ${buttonColor} !important;
    }`,
    `#formaloo-widget-form .widget-field-theme .widget-singleStep-dropdown-theme {
      outline-color: ${buttonColor};
    }`,
    // multi-step common theme
    `#formaloo-widget-form .widget-field-theme .widget-multiStep-checkbox input:checked ~ label .widget-multiStep-checkbox-mark {
      border: 1px solid ${buttonColor};
    }`,
    `#formaloo-widget-form .widget-field-theme .widget-multiStep-checkbox input:checked ~ label .widget-multiStep-checkbox-mark::after {
      border: solid ${buttonColor};
      border-width: 0 3px 3px 0;
    }`,
    `#formaloo-widget-form .widget-field-theme .widget-multiStep-choice input:checked ~ label .widget-multiStep-choice-mark {
      border: 1px solid ${buttonColor};
    }`,
    `#formaloo-widget-form .widget-field-theme .widget-multiStep-choice input:checked ~ label .widget-multiStep-choice-mark::after {
      background: ${buttonColor};
    }`,
    `#formaloo-widget-form .widget-field-theme .widget-textInput-theme {
      outline-color: ${buttonColor} !important;
      border-color: ${jsonColorConvertor(buttonJsonColor, 0.6)} !important;
    }`,
    `#formaloo-widget-form .widget-field-theme .widget-fileWrapper-theme {
      background-color: ${jsonColorConvertor(buttonJsonColor, 0.6)};
      outline-color: ${buttonColor};
      color: ${textColor} 
    }`,
    `#formaloo-widget-form .widget-field-theme .widget-dropdown-theme {
      outline-color: ${buttonColor};
      border-color: ${jsonColorConvertor(buttonJsonColor, 0.6)};
    }`,
    // multi-step => side-widget theme
    `#formaloo-widget-form .widget-field-theme .widget-sideWidget-nps-theme > label:hover  {
      background-color: ${buttonColor};
      border-color: ${buttonColor};
      color: ${textColor};
    }`,
    `#formaloo-widget-form .widget-field-theme .widget-sideWidget-nps-theme > input:checked ~ label  {
      background-color: ${buttonColor};
      border-color: ${buttonColor};
      color: ${textColor};
    }`,
  ]

  applyStylesToStyleSheet(styleSheet, rules)
}