let poly = require("preact-cli/lib/lib/webpack/polyfills");

import { h } from "preact";
import habitat from "preact-habitat";
import Widget from "./components/widget";
import axiosConfig from './api/config/axios.config'

//
// ─── INITIAL SETUP ──────────────────────────────────────────────────────────────
//
axiosConfig()

//
// ─── INITIAL WIDGET & PROPS ──────────────────────────────────────────────────────────────
//
let _habitat = habitat(Widget);

_habitat.render({
  selector: '[data-widget-form="formaloo-widget"]',
  clean: true
});
